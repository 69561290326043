<div class="setting-menu">


  <div class="p-3">

  <inline-title [title]="'ADMINISTRATION'|translate" [hideText]="!showTitles" size="xlarge" color="orange" icon="bi-gear"/>
  </div>
  <hr>

  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/organization" routerLinkActive="active">
    <inline-title icon="bi-building" size="large" [title]="'organization'|translate"
                  [hideText]="!showTitles"
    />
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/users"
       routerLinkActive="active">
    <inline-title icon="bi-person" size="large" [title]="'employees'|translate"
                  [hideText]="!showTitles"
    />
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/teams"
       routerLinkActive="active">

    <inline-title icon="bi-people" size="large" [title]="'teams'|translate"
                  [hideText]="!showTitles"
    />
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/jobs"
       routerLinkActive="active">
    <inline-title icon="bi-briefcase" size="large" [title]="'jobs'|translate"
                  [hideText]="!showTitles"
    />
  </div>
  <div class="menu-item" [ngClass]="showTitles?'':'justify'" routerLink="/panel/setting/locations"
       routerLinkActive="active">
    <inline-title icon="bi-pin-map" size="large" [title]="'locations'|translate"
                  [hideText]="!showTitles"
    />

  </div>

  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/integrations"
       routerLinkActive="active">

    <inline-title icon="bi-bezier2" size="large" [title]="'integrations'|translate"
                  [hideText]="!showTitles"
    />
  </div>

  <div class="menu-item" *ngIf="activePlan != 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/billing" routerLinkActive="active">


    <inline-title icon="bi-wallet2" size="large" [title]="'billing'|translate"
                  [hideText]="!showTitles"
    />

  </div>
  <div class="menu-item" *ngIf="activePlan != 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/invoices" routerLinkActive="active">

    <inline-title icon="bi-receipt" size="large" [title]="'INVOICES'|translate"
                  [hideText]="!showTitles"
    />

  </div>
  <div class="menu-item" *ngIf="activePlan == 'Lifetime'" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/setting/license" routerLinkActive="active">


    <inline-title icon="bi-receipt" size="large" [title]="'LICENSE'|translate"
                  [hideText]="!showTitles"
    />

  </div>

</div>
