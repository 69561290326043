<div class="nav-container" [ngClass]="isCollapse ? 'nav-collapsed' : 'nav-expanded'">
  <div class="nav-content">
    <div
      style="width:48px;height:100%;border-inline-end: 1px solid var(--border-color)"
      class="d-flex flex-column gap-1 overflow-none align-items-center bg-darker">
      <atw-image
        (click)="goToHome()"
        type="atwork"
        style="width:36px;height:36px;min-height:36px;margin-top:6px;cursor:pointer;"></atw-image>
      <hr>
      <div cdkDropList cdkDropListOrientation="vertical" cdkDropListLockAxis="y"
           id="tour_workspaces"
           [cdkDropListData]="pinnedSpaces" (cdkDropListDropped)="spaceChangeSort($event)"
           class="all-spaces-holder d-flex flex-column gap-2 flex-grow-1 w-100 mt-2 align-items-center">
          <span placement="end" [ngbTooltip]="'workspaces'|translate"
                (click)="onLaunchpadClick();$event.stopPropagation()"
                class="top-item cursor-pointer  border-radius opacity-50 opacity-100-hover mb-2">
              <img src="assets/media/svg/app_launch.svg" width="24" height="24">
            </span>

        @for (space of pinnedSpaces; track $index) {

          <icon cdkDrag [icon]="space.icon" size="xlarge"
                [class.top-item-active]="space.id == currentSpaceId"
                routerLink="/panel/space/{{space.id}}/home"
                class="top-item cursor-pointer"
                [ngbTooltip]="space.name" container="body" placement="end"
          />

        }
        @if (tempSpace) {

        <hr style="width:80%">
          <icon [icon]="tempSpace.icon" size="xlarge"
                [class.top-item-active]="tempSpace.id == currentSpaceId"
                routerLink="/panel/space/{{tempSpace.id}}/home"
                class="top-item cursor-pointer"
                [ngbTooltip]="tempSpace.name" container="body" placement="end"
          />
        }

      </div>

      <div class="d-flex flex-column gap-4 w-100 bg-darker pt-4 pb-4">
        <!--        <hr>-->

        <icon
          icon="bi-chat-dots"
          [badge]="chatBadge"
          [active]="focusArea == 'chat'"
          size="xlarge"
          class="top-item setting-item"
          (click)="messages()"
          ngbTooltip="{{'INBOX' | translate }}"
          container="body"
          placement="auto"
          routerLink="/panel/chat"
          routerLinkActive="orange"
          [ngClass]="focusArea == 'chat' ? 'top-item-active' : ''"
        />

        <icon
          icon="bi-ui-checks"
          [active]="focusArea == 'forms'"
          size="xlarge"
          class="top-item setting-item"
          ngbTooltip="{{'FORMS' | translate}}"
          container="body"
          placement="auto"
          (click)="forms()"
          [ngClass]="focusArea == 'forms' ? 'top-item-active' : ''"
        />


        <icon
          *ngIf="isOwner"
          icon="bi-gear"
          [active]="focusArea == 'setting'"
          size="xlarge"
          class="top-item setting-item"
          (click)="administration()"
          ngbTooltip="{{'ADMINISTRATION' | translate }}"
          container="body"
          placement="auto"
          [ngClass]="focusArea == 'setting' ? 'top-item-active' : ''"
        />
      </div>

    </div>


    <app-nav-space [showTitles]="!isCollapse" [hidden]="focusArea != 'space'"/>
    <app-nav-messaging [showTitles]="!isCollapse" [hidden]="focusArea != 'chat'"/>
    <app-nav-setting [showTitles]="!isCollapse" [hidden]="focusArea != 'setting'"/>
    <atw-nav-profile [showTitles]="!isCollapse" [hidden]="focusArea != 'profile'"/>
    <app-nav-forms [showTitles]="!isCollapse" [hidden]="focusArea != 'forms'"
                   [refresh]="focusArea == 'forms'"/>
  </div>


  <div
    class="nav-anchor {{isRtl?'rtl-nav':'ltr-nav'}}"
    (click)="onToggleCollapseClick()"
    [ngClass]="isCollapse ? 'anchor-collapsed' : 'anchor-expanded'"
  >
    <i class="anchor-left bi {{isRtl?'bi-chevron-double-right':'bi-chevron-double-left'}}"></i>
    <span class="anchor-pillar"></span>
    <i class="anchor-right bi {{isRtl?'bi-chevron-double-left':'bi-chevron-double-right'}}"></i>
  </div>
</div>
