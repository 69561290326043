<div class="chat-holder">
  <div class="p-3">

    <inline-title [title]="'INBOX'|translate" [hideText]="!showTitles" size="xlarge"
                  color="orange" icon="bi-chat-dots"/>
  </div>
  <hr>
  <div class="chat-item" [class.hide-title]="!showTitles" style="margin-top: 0.5rem;"
       routerLink="/panel/chat/notifications" routerLinkActive="chat-item-active">
    <icon icon="bi-bell" size="xlarge" [ngbTooltip]="!showTitles?'Notifications':''"
          container="body"></icon>
    <span [hidden]="!showTitles">
          {{ 'NOTIFICATIONS' | translate }}
        </span>
  </div>
  <hr>

  @if (showTitles) {

    <div ngbDropdown #searchDropdown="ngbDropdown">
      <input ngbDropdownAnchor class="form-control w-100" [placeholder]="'search'|translate"
             (keyup)="searchDropdown.open()"
             type="search" [(ngModel)]="searchText">
      <div ngbDropdownMenu>
        @for (conversable of conversables |deepSearch:'name':searchText; track $index) {
          <inline-title ngbDropdownItem
                        (click)="onSearchItemClick(conversable)"
                        iconType="image" [title]="conversable.name" size="small"
                        [icon]="conversable.avatar||''"/>
        } @empty {
          <div class="d-flex align-content-center">{{ 'not_found'|translate }}</div>
        }
      </div>
    </div>
  }


  <div class="mt-2 d-flex flex-column gap-1">
    @for (conversation of conversables; track $index) {
      @if (conversation.conversationId) {
        <inline-title
          class="chat-item"
          routerLink="/panel/chat/{{conversation.conversationId}}"
          routerLinkActive="chat-item-active"
          [hideText]="!showTitles"
          iconType="image" [title]="conversation.name" size="small" [icon]="conversation.avatar!!"/>
      }
    }
  </div>
</div>
