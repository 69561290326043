import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ImportCsvApiServices } from 'src/app/data/api/import-csv-api.service';
import { DataTypeEnum } from 'src/app/data/enums/data-type.enum';
import { DatatypeDto } from 'src/app/data/objects/datatype.dto';
import { ImportFileAttributeDto } from 'src/app/data/objects/import-file-attribute.dto';
import { SheetRepository } from 'src/app/data/repository/sheet.repository';
import { ImportCsvRequest } from 'src/app/data/request/import-csv.request';
import { NavigationService } from 'src/app/navigation/navigation.service';

@Component({
  selector: 'app-preview-of-import-file',
  templateUrl: './preview-of-import-file.component.html',
  styleUrl: './preview-of-import-file.component.scss',
})
export class PreviewOfImportFileComponent implements OnInit {
  @Input() dataOfImport: any;
  headerOfTable: Array<string> = [];
  bodyOfTable: Array<any> = [];
  removedColumns: Array<any> = [];
  includedHeader: boolean = true;
  dataTypesList: Array<DatatypeDto> = [];
  selectedRadio: number;
  importFileManage: Array<ImportFileAttributeDto> = [];
  finalAttributes: Array<ImportFileAttributeDto> = [];
  backUpAttributes: Array<ImportFileAttributeDto> = [];
  isDone: boolean = false;
  defaultIcon: string = 'bi-file-earmark-arrow-down';
  iconSelect: string;
  constructor(
    private sheetRepo: SheetRepository,
    private activeModal: NgbActiveModal,
    private _toast: ToastrService
  ) {}
  async ngOnInit() {
    if (this.dataOfImport) {
      for (let i = 0; i < this.dataOfImport.data.length; i++) {
        if (i === 0) {
          this.headerOfTable = this.dataOfImport.data[i];
          continue;
        } else {
          this.bodyOfTable.push(this.dataOfImport.data[i]);
        }
      }
    }
    this.dataTypesList = (await this.sheetRepo.getDataTypes()).filter((type) => type.importable);
    let defaultDataTypeId = this.dataTypesList.find((dataType:DatatypeDto)=>dataType.name === DataTypeEnum.ShortText);
    if (defaultDataTypeId) {
      for (let i = 0; i < this.headerOfTable.length; i++) {
        this.importFileManage.push({
          name: this.headerOfTable[i],
          isPrimary: false,
          index: i,
          dataTypeId: defaultDataTypeId?.id,
          isHide: false,
        });
      }
    } else {
      for (let i = 0; i < this.headerOfTable.length; i++) {
        this.importFileManage.push({
          name: this.headerOfTable[i],
          isPrimary: false,
          index: i,
          dataTypeId: 10,
          isHide: false,
        });
      }
    }
    this.finalAttributes = this.importFileManage;
  }
  includeHeaderFunc(event: any) {
    this.includedHeader = event.target.checked;
    let headerTemp: Array<string> = [];
    headerTemp = this.headerOfTable;
    if (!this.includedHeader) {
      this.bodyOfTable.unshift(headerTemp);
      this.headerOfTable = [];
      for (let i = 0; i < headerTemp.length; i++) {
        let newHeader = `column-${i}`;
        this.headerOfTable.push(newHeader);
        if (this.finalAttributes[i].name !== 'Title') {
          this.finalAttributes[i].name = newHeader;
        }
      }
    } else {
      this.headerOfTable = this.bodyOfTable.shift();
      for (let i = 0; i < this.headerOfTable.length; i++) {
        this.finalAttributes[i].name = this.headerOfTable[i];
      }
    }
  }
  addOrRemoveColumn(event: any, item: ImportFileAttributeDto, indx: number) {
    if (!event.target.checked) {
      const removedColumn: Array<any> = [];
      this.importFileManage.forEach((itemShow: ImportFileAttributeDto) => {
          if (itemShow.name === item.name) {
              itemShow.isHide = true;
          }
      });

      let backUpAttributes: Array<ImportFileAttributeDto> = [];
      backUpAttributes = this.finalAttributes.filter((attr) => attr.name === item.name);
      this.finalAttributes = this.finalAttributes.filter(
          (column: ImportFileAttributeDto) => column.name !== item.name
      );
      this.bodyOfTable.forEach((row: any) => {
          if (indx >= 0 && indx < row.length) {
              removedColumn.push(row[indx]);
              row.splice(indx, 1);
          }
      });
      this.removedColumns.push(removedColumn);
      this.backUpAttributes.push(...backUpAttributes);

  } else {
      const restoredColumns = this.removedColumns.pop();
      const restoredAttributes = this.backUpAttributes.filter(attr => attr.name === item.name);

      if (restoredAttributes.length > 0) {
          restoredAttributes.forEach((attr) => {
              this.finalAttributes.splice(attr.index, 0, attr);
          });
          this.backUpAttributes = this.backUpAttributes.filter(attr => attr.name !== item.name);
      }
      if (restoredColumns !== undefined) {
          restoredColumns.forEach((column: any, index: number) => {
              this.bodyOfTable[index].splice(indx, 0, column);
          });
      }
      this.importFileManage.forEach((itemShow: ImportFileAttributeDto) => {
          if (itemShow.name === item.name) {
              itemShow.isHide = false;
          }
      });
  }
  }
  setPrimary(event: any, item: ImportFileAttributeDto) {
    if (event.target.checked) {
      this.finalAttributes.forEach((attr: ImportFileAttributeDto) => {
        if (attr.isPrimary) {
          attr.isPrimary = false;
        }
      });
      item.isPrimary = true;
      this.isDone = true;
    } else {
      item.isPrimary = false;
    }
  }
  async sendFile() {
    this.isDone = false;
    if (this.finalAttributes.length === 0) {
      this.finalAttributes = this.importFileManage;
    }

    let sortedAttr: Array<ImportFileAttributeDto> = [];
    for (let i = 0; i < this.finalAttributes.length; i++) {
      sortedAttr.push({
        name: this.finalAttributes[i].name,
        isPrimary: false,
        index: i,
        dataTypeId: +this.finalAttributes[i].dataTypeId!,
      });
    }
    sortedAttr.map((item: ImportFileAttributeDto) => {
      item.index === this.selectedRadio
        ? (item.isPrimary = true) && delete item.dataTypeId
        : (item.isPrimary = false);
    });
    if (!this.iconSelect) {
      this.iconSelect = this.defaultIcon;
    }
    const formData = new FormData();
    formData.append('file', this.dataOfImport.file);
    formData.append('name', this.dataOfImport.sheetName);
    formData.append('spaceId', this.dataOfImport.dataOfSpace.spaceId);
    formData.append('folderId', this.dataOfImport.dataOfSpace.folderId);
    formData.append('icon', this.iconSelect);
    formData.append('hasHeader', this.includedHeader.toString());
    formData.append('attributes', JSON.stringify(sortedAttr));
    let res = await this.sheetRepo.importFile(formData);
    this.activeModal.close(res);
  }
  async iconUpdate(icon: string) {
    this.iconSelect = icon;
  }
}
