import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  PreviewOfImportFileComponent
} from './preview-of-import-file/preview-of-import-file.component';
import * as Papa from 'papaparse';
import { SheetDto } from '../data/objects/Sheet.dto';

@Component({
  selector: 'app-import-files',
  templateUrl: './import-files.component.html',
  styleUrl: './import-files.component.scss'
})
export class ImportFilesComponent {
  @Input() folderSpace: { spaceId:number, folderId :number};
  importFile: File;
  sheetName: string | null = null;

  constructor(private activeModal: NgbActiveModal, private modal: NgbModal) {
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      console.error('No files selected');
      return;
    }
    const file: File = input.files[0];
    if (file) {
      this.importFile = file;
    }
  }

  nextBtn() {
    const file = this.importFile;
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = reader.result as string;
      Papa.parse(csv, {
        complete: (result) => {
          let sheet:SheetDto;
          let modalRef = this.modal.open(PreviewOfImportFileComponent, {
            animation: true,
            size: 'xl',
          })
          modalRef.componentInstance.dataOfImport = {
            data: result.data,
            sheetName: this.sheetName,
            file: file,
            dataOfSpace: this.folderSpace
          }
          modalRef.result.then((result)=>{
            sheet = result;
          this.activeModal.close(sheet);
          })
        }
      });
    };
    reader.readAsText(file);
  }

  closeModal() {
    this.activeModal.close()
  }
}
