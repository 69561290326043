<div class="row m-0">
  <div class="col-12 border-bottom pt-4 pb-4">
    <p class="fw-bold m-0 p-0 ">{{'IMPORT_CSV_FILE' | translate }}</p>
  </div>
  <div class="col-12 pt-4 pb-4">
    <label  class="form-label">{{'SHEET_NAME' | translate }}</label>
    <input type="text" class="form-control mb-2" [(ngModel)]="sheetName">
    <label for="formFile" class="form-label">{{'CHOOSE_FILE' | translate }}</label>
    <input (change)="onFileChange($event)" class="form-control mb-3" type="file" id="formFile" accept=".csv">
  </div>
  <div class="col-12 border-top pt-4 pb-4">
    <button class="btn btn-sm btn-primary float-end" (click)="nextBtn()" [disabled]="!importFile || !sheetName">{{'NEXT' | translate }}</button>
    <button class="btn btn-sm text-primary float-start" (click)="closeModal()">{{'cancel' | translate }}</button>
  </div>
</div>
