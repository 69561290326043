<div class="d-flex flex-column w-100 gap-2 p-2">

  <div class="p-3">

    <inline-title [title]="'myProfile'|translate" [hideText]="!showTitles" size="xlarge" color="orange" icon="bi-person"/>
  </div>
  <hr>

  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/profile/overview" routerLinkActive="active">
    <inline-title icon="bi-person-lines-fill" size="xlarge" [title]="'profile_overview'|translate"
                  [hideText]="!showTitles"
    />
  </div>

  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/profile/password" routerLinkActive="active">
    <inline-title icon="bi-fingerprint" size="xlarge" [title]="'change_password'|translate"
                  [hideText]="!showTitles"
    />
  </div>

  <div class="menu-item" [ngClass]="showTitles?'':'justify'"
       routerLink="/panel/profile/preferences" routerLinkActive="active">
    <inline-title icon="bi-sliders" size="xlarge" [title]="'preferences'|translate"
                  [hideText]="!showTitles"
    />
  </div>


</div>
