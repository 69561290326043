import { Component, Input, OnInit } from '@angular/core';
import { GptConversationDto } from 'src/app/data/objects/gpt-conversation.dto';
import { ChatService } from 'src/app/chat/chat.service';
import { ConversationDto } from 'src/app/data/objects/chat.dtos';
import { BaseResponse } from '../../data/response/base.response';
import { EmployeeDto } from '../../data/objects/employee.dto';
import { ConversationTypeEnum } from '../../data/enums/conversation-type.enum';
import { SheetRepository } from '../../data/repository/sheet.repository';
import { ToastService } from '../../share/services/toast.service';
import { NavigationService } from '../navigation.service';
import { UtilService } from '../../share/utils/util.service';
import {log} from "../../share/utils/logger";

@Component({
  selector: 'app-nav-messaging',
  templateUrl: './nav-messaging.component.html',
  styleUrls: ['./nav-messaging.component.scss'],
})
export class NavMessagingComponent implements OnInit {
  @Input() showTitles: boolean = true;

  gptChats: GptConversationDto[] = [];
  conversations: ConversationDto[] = [];
  searchText: string;

  conversables: Conversable[] = [];

  employees: EmployeeDto[] = [];

  constructor(
    private chatApi: ChatService,
    private sheetRepo: SheetRepository,
    private toaster: ToastService,
    private navigator: NavigationService,
    private utils: UtilService,
  ) {}

  async ngOnInit() {
    this.getMyConversations();
  }

  async getConversables() {
    let employees = await this.sheetRepo.getEmployees();
    let me = await this.sheetRepo.getMyEmployee();
    this.conversables.push(
      ...employees.filter(emp=>emp.id!=me.id).map((emp) => {
        let conversation = this.conversations.find(
          (conv) =>
            (conv.refId == emp.UserId || conv.createdBy == emp.UserId) &&
            conv.type == ConversationTypeEnum.PRIVATE && conv.refId !=conv.createdBy,
        );
        return {
          type: ConversationTypeEnum.PRIVATE,
          conversationId: conversation?.id,
          name: `${emp.FullName.firstName} ${emp.FullName.lastName}`,
          refId: emp.UserId,
          avatar: emp.Icon,
        };
      }),
    );


    log('conversables',this.conversables);
  }

  getMyConversations() {
    this.chatApi.getMyConversations().subscribe((response) => {
      if (BaseResponse.isSuccess(response)) {
        this.conversations = response.data;
        this.getConversables();
      }
    });
  }

  // async newChatAI() {
  //   let newReq = new CreateNewChatAiRequest();
  //   newReq.content = 'Hi';
  //   let res = await this.chatApi.createNewChatAI(newReq);
  //   this.gptChats.push(res.data);
  //   this.router.navigateByUrl(`/panel/chat/ai/${res.data.id}`);
  // }
  onSearchItemClick(conversable: Conversable) {
    if (!conversable.conversationId) {
      this.chatApi
        .createPrivateConversation(this.utils.generateConversationId(), conversable.refId)
        .subscribe((response) => {
          if (BaseResponse.isSuccess(response)) {
            conversable.conversationId = response.data.id;
            this.conversations.push(response.data);
          } else {
            this.toaster.error(response.error);
          }
        });
    }
  }
}

export class Conversable {
  conversationId?: string;
  type: ConversationTypeEnum;
  refId: string|number;
  name: string;
  avatar: string | null;
}
