import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormDto} from 'src/app/data/objects/form.dto';
import {SheetRepository} from "../../data/repository/sheet.repository";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-forms',
  templateUrl: './nav-forms.component.html',
  styleUrls: ['./nav-forms.component.scss']
})
export class NavFormsComponent implements OnChanges {
  @Input() showTitles: boolean = true;
  @Input() refresh: boolean;
  forms: FormDto[] | undefined;

  constructor(private sheetRepo: SheetRepository,
              private router: Router) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.getForms();
  }

  async getForms() {
    this.forms = await this.sheetRepo.getFillerForms();
    if (this.forms && this.forms.length > 0) {
      if (this.router.url.replace('/panel/forms', '').length <= 1) {
        setTimeout(() => {
          if (this.forms) (document.getElementById(this.forms[0].code) as HTMLElement).click();
        }, 10);
      }
    }
  }

}
