<div class="p-2 d-flex flex-column h-100">
  <div class="p-3">

    <inline-title [title]="'FORMS'|translate" [hideText]="!showTitles" size="xlarge"
                  color="orange" icon="bi-ui-checks"/>
  </div>
  <hr>

  <div class="overflow-auto-hover flex-grow-1">

    @for (form of forms; track $index) {

      <div class="form-item" [ngClass]="showTitles?'':'justify'"
           routerLink="/panel/forms/{{form.code}}" routerLinkActive="form-item-active">
        <inline-title [icon]="form.icon" size="large" [title]="form.name"
                      [hideText]="!showTitles"
        />
      </div>
    }

  </div>
</div>
