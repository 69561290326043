import { SheetItemBaseDto } from './sheet-item-base.dto';

export class EmployeeDto extends SheetItemBaseDto {
  orgId: number;
  FullName: FullNameDto = new FullNameDto();
  Job: string | null;
  Team: string | null;
  Location: number | null;
  Email: string;
  UserId: number;
  Timezone: string | null;
  TeamName: string | null;
  JobName: string | null;
}

export class FullNameDto {
  lastName: string = '';
  firstName: string = '';

  public toString(fullname: FullNameDto): string {
    return `${fullname.firstName} ${fullname.lastName}`;
  }
}
