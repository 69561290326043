import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './navigation.service';
import { Router } from '@angular/router';
import { EmployeeDto } from '../data/objects/employee.dto';
import { Title } from '@angular/platform-browser';
import { BasicsRepository } from '../data/repository/basics.repository';
import { SheetRepository } from '../data/repository/sheet.repository';
import { UrlChangeListener, UrlEvents } from '../events/url.events';
import { Subscription } from 'rxjs';
import {
  NotificationEvents,
  NotificationListener,
  UnseenCountListener,
} from '../events/notification.events';
import { NotificationDto } from '../data/objects/notification.dto';
import { SpaceDto } from '../data/objects/space.dto';
import { AllSpacesDialogComponent } from '../space/all-spaces-dialog/all-spaces-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlUtil } from '../share/utils/url.util';
import { log } from '../share/utils/logger';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import {PreferencesEvents, PreferencesListener} from '../events/preferences.events';
import {PreferencesDto} from "../data/objects/user.dtos";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent
  implements
    OnInit,
    UrlChangeListener,
    OnDestroy,
    NotificationListener,
    UnseenCountListener,
    PreferencesListener
{
  isCollapse = false;
  focusArea: 'space' | 'setting' | 'chat' | 'forms' | 'profile' | string = 'space';

  employee: EmployeeDto = new EmployeeDto();
  chatBadge: number = 0;
  isOwner = false;
  subscribes = new Subscription();
  isRtl: boolean = false;
  currentSpaceId: string | number;
  spaces: SpaceDto[] = [];
  pinnedSpaces: SpaceDto[] = [];
  preferences: PreferencesDto;
  tempSpace: SpaceDto | undefined;

  constructor(
    private navService: NavigationService,
    private titleService: Title,
    private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private router: Router,
    private modal: NgbModal,
    private urlEvents: UrlEvents,
    private urlUtils: UrlUtil,
    private prefsEvents: PreferencesEvents,
    private notifEvents: NotificationEvents,
  ) {
    this.subscribes.add(this.urlEvents.onUrlChange(this));
    this.urlEvents.subscribeChangeSpace(this);
    this.subscribes.add(this.notifEvents.subscribeNotification(this));
    this.subscribes.add(this.notifEvents.subscribeUnseenCount(this));
    this.subscribes.add(this.notifEvents.subscribeUnseenCount(this));
    this.subscribes.add(this.prefsEvents.subscribePreferences(this));
  }

  onPreferencesChanged(prefs: PreferencesDto): void {
    this.preferences = prefs;
    this.getPinnedSpaces();
  }

  async onSpaceChanged(from: string, to: string) {
    if (!to) return;
    this.currentSpaceId = to;
    let space = this.spaces.find(space=>`${space.id}` == to);
    if(space && !space?.clientData.pinned){
      this.tempSpace = space;
    }

  }

  onUnseenNotifCountChange(count: number): void {
    this.chatBadge = count;
  }

  onNewNotification(notif: NotificationDto): void {
    this.chatBadge++;
  }

  ngOnDestroy(): void {
    this.subscribes.unsubscribe();
  }

  onUrlChange(url: string) {
    this.initFocusArea();
  }

  async ngOnInit() {
    this.isRtl = this.baseRepo.isRtl();
    this.spaces = await this.sheetRepo.getSpaces();
    this.preferences = this.baseRepo.getPreferences();
    this.getPinnedSpaces();

    this.isOwner = await this.baseRepo.amIOwner();
    this.initFocusArea();
    await this.navService.getAllSpaces();
    this.employee = await this.navService.getMyEmployee();
    let notifs = await this.baseRepo.getUnseenNotifications();
    if (notifs && notifs.length > 0) {
      this.chatBadge = notifs.length;
    }
  }

  getPinnedSpaces() {
    this.pinnedSpaces = this.spaces
      .filter((space) => space.clientData.pinned)
      .sort((a, b) => {
        let aIndex = this.preferences.pinSpaces.indexOf(a.id);
        let bIndex = this.preferences.pinSpaces.indexOf(b.id);
        if (aIndex > -1 && bIndex > -1) return aIndex - bIndex;
        return 1;
      });


  }

  setCurrentTempSpace(){
    if(this.currentSpaceId){
      let space = this.spaces.find(space=>space.id == this.currentSpaceId);
      if(space && !space?.clientData.pinned){
        this.tempSpace = space;
      }
    }
  }

  initFocusArea() {
    this.focusArea = this.router.url.split('/')[2];
    this.currentSpaceId = this.urlUtils.getSpaceId();
    this.setCurrentTempSpace();
    if (this.focusArea == 'setting') {
      this.titleService.setTitle('Atwork | Administration');
    } else if (this.focusArea == 'chat') {
      this.titleService.setTitle('Atwork | Messages');
    } else if (this.focusArea == 'forms') {
      this.titleService.setTitle('Atwork | Forms');
    } else if (this.focusArea == 'space') {
    }else if(this.focusArea == 'profile'){
      this.titleService.setTitle('Atwork | Profile');
    }
  }

  onToggleCollapseClick() {
    this.isCollapse = !this.isCollapse;
  }

  backToSpace() {
    this.focusArea = 'space';
    if (this.navService.currentSpaceId == undefined) {
      this.navService.goToDefaultSpace();
    } else this.navService.goToCurrentSpace();
  }

  messages() {
    this.focusArea = 'chat';
    this.titleService.setTitle('Atwork | Messages');
    this.router.navigateByUrl('/panel/chat');
  }

  profile() {
    this.focusArea = 'profile';
    this.titleService.setTitle('Atwork | Profile');
    this.router.navigateByUrl('/panel/profile');
  }

  administration() {
    this.focusArea = 'setting';
    this.titleService.setTitle('Atwork | Administration');
    this.router.navigateByUrl('/panel/setting');
  }

  forms() {
    this.focusArea = 'forms';
    this.titleService.setTitle('Atwork | Forms');
    this.router.navigateByUrl('/panel/forms');
  }

  logout() {
    localStorage.clear();
    this.sheetRepo.clear();
    this.router.navigateByUrl('/');
  }

  goToHome() {
    this.focusArea = 'space';
    this.navService.goToDefaultSpace();
  }

  onLaunchpadClick() {
    this.modal.open(AllSpacesDialogComponent, { size: 'xl' });
  }

  spaceChangeSort($event: CdkDragDrop<SpaceDto[]>) {
    transferArrayItem(
      $event.previousContainer.data,
      $event.container.data,
      $event.previousIndex,
      $event.currentIndex,
    );
    let pinSpaces = this.pinnedSpaces.reduce((acc, item) => {
      acc.push(item.id);
      return acc;
    }, [] as number[]);
    log('sort', pinSpaces);
    this.baseRepo.sortPinnedSpaces(pinSpaces);
  }
}
