import {Component, Input} from '@angular/core';

@Component({
  selector: 'atw-nav-profile',
  templateUrl: './nav-profile.component.html',
  styleUrl: './nav-profile.component.scss'
})
export class NavProfileComponent {
  @Input() showTitles: boolean = true;

}
