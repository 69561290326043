import {Component, Input, OnInit} from '@angular/core';
import {StatusCode} from "../../share/config/statusCode";
import {NavigationService} from "../navigation.service";
import {ToastrService} from "ngx-toastr";
import {SubscriptionResponse} from "../../data/response/subscription.response";

@Component({
  selector: 'app-nav-setting',
  templateUrl: './nav-setting.component.html',
  styleUrls: ['./nav-setting.component.scss']
})
export class NavSettingComponent implements OnInit {
  @Input() showTitles: boolean = true;
  activePlan: string = '';

  constructor(private navService: NavigationService,
              private _toastr: ToastrService) {
  }

  async ngOnInit() {
    if (localStorage.getItem('role') == 'OWNER') await this.activeSubscription();
  }

  async activeSubscription() {
    const query: string = `isActive[eq]=true`;
    await this.navService.getActiveSubscription(query).then((response: SubscriptionResponse) => {
      this.activePlan = response.data[0].plan.name;
    }, error => {
      this._toastr.error(StatusCode[error.error.statusCode]);
    });
  }
}
